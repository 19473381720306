<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="logo-header">
        <ion-img src="/assets/logo.png"></ion-img>
      </div>

      <div class="location-block">
        <h2>History</h2>
        <!-- <p>{{ submissions.length }} SUGEZT Submissions</p> -->
      </div>
      <div class="container">
        <ion-grid>
          <ion-row
            class="submission-result"
            v-for="submission in submissions"
            :key="submission"
            @click="showSubmittion(submission)"
          >
            <div class="submission-result-group">
              <p class="submission-result-location">
                {{ submission.place.name }}
              </p>
              <p class="submission-result-content">
                {{ truncate(submission.content) }}
              </p>
            </div>
            <div class="submission-result-type" :class="submission.type">
              <span>{{ submission.type }}</span>
              {{ submission.date }}
            </div>
          </ion-row>
        </ion-grid>
        <ion-buttons>
          <ion-button :disabled="hasPreviousPage" @click="previousPage"
            ><ion-icon :icon="chevronBackOutline"
          /></ion-button>
          <ion-button :disabled="hasNextPage" @click="nextPage"
            ><ion-icon :icon="chevronForwardOutline"
          /></ion-button>
        </ion-buttons>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import firebase from "firebase/app";
require("firebase/firestore");

import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonImg,
  IonButtons,
  IonButton,
  IonIcon,
  modalController,
} from "@ionic/vue";
import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";

import ViewSubmissionModal from "@/components/ViewSubmissionModal.vue";
import { ref } from "vue";

const PAGE_LIMIT = 5;

export default {
  name: "History",
  components: {
    IonContent,
    IonPage,
    IonImg,
    IonGrid,
    IonRow,
    IonButtons,
    IonButton,
    IonIcon,
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  setup() {
    const submissions = ref([]);
    const db = firebase.firestore();
    const submissionsCollection = db.collection("submissions");
    const querySnapshot = submissionsCollection
      .orderBy("datetime", "desc")
      .where("uid", "==", firebase.auth().currentUser.uid)
      .limit(PAGE_LIMIT).get();

    querySnapshot.then((snapshot) => {
      submissions.value = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });

    });
    return {
      submissions,
      chevronForwardOutline,
      chevronBackOutline,
      submissionsCollection,
    };
  },
  computed: {
    hasPreviousPage() {
      return this.currentPage <= 1;
    },
    hasNextPage() {
      if (this.submissions === undefined) {
        return false;
      }
      return this.submissions.length != PAGE_LIMIT;
    },
  },
  methods: {
    previousPage() {
      const querySnapshot = this.submissionsCollection
        .orderBy("datetime", "desc")
        .where("uid", "==", firebase.auth().currentUser.uid)
        .endBefore(this.submissions[0].datetime)
        .limitToLast(PAGE_LIMIT)
        .get();

      querySnapshot.then((snapshot) => {
        const results = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        this.submissions = results
        this.currentPage -= 1
      });


    },
    nextPage() {
      const querySnapshot = this.submissionsCollection
        .orderBy("datetime", "desc")
        .where("uid", "==", firebase.auth().currentUser.uid)
        .startAfter(this.submissions[this.submissions.length - 1].datetime)
        .limit(PAGE_LIMIT).get();

      querySnapshot.then((snapshot) => {
        const results = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        this.submissions = results

        this.currentPage += 1
        console.log(this.currentPage);
      });
    },
    truncate: (input) => {
      if (input == null || input == undefined) return;
      return input.length > 100 ? `${input.substring(0, 100)}...` : input;
    },
    showSubmittion(submission) {
      modalController
        .create({
          component: ViewSubmissionModal,
          componentProps: {
            submission: submission,
          },
        })
        .then((modal) => modal.present());
    },
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
}
h2 {
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 2em;
  text-transform: uppercase;
}
.location-block {
  background: linear-gradient(
    130deg,
    rgba(83, 168, 186, 1) 20%,
    rgba(118, 204, 222, 1) 90%
  );
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  padding: 1em;
}
.location-block p {
  margin: 0;
  margin-bottom: 0.5em;
}
.submission-result {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ddd;
  background: #fff;
  border-radius: 0.5em;
}
.submission-result-group {
  padding: 1em;
  flex: 1;
  flex-basis: 60%;
}
.submission-result-location {
  font-weight: bold;
  padding: 0;
  margin: 0;
}
.submission-result-content {
  padding: 0;
  padding-top: 0.5em;
  margin: 0;
  word-break: break-all;
}
.submission-result-type {
  padding: 1em;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.submission-result-type.Critique {
  background-color: #57af2d;
}
.submission-result-type.Inspire {
  background-color: #ec1380;
}
.submission-result-type.Praise {
  background-color: #54127f;
}
.submission-result-type.Enhance {
  background-color: #f59015;
}
.submission-result-type span {
  font-size: 1rem;
  display: block;
  font-weight: bold;
  padding-bottom: 0.5em;
}

ion-grid,
ion-content {
  --background: #efeff4;
  --color: #000;
  background: #efeff4;
  color: #000;
}
ion-row:not(:last-child) {
  margin-bottom: 0.5em;
}
ion-buttons {
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 2em;
}
ion-button {
  --background: #fff !important;
  --border-style: solid !important;
  --border-width: 1px !important;
  --border-color: #ddd !important;
}
</style>